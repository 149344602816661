@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    font-family: 'Space Grotesk', sans-serif;
}

.sidebar-animate{
    animation: left-open 0.5s linear;
}
@keyframes left-open {
    from{transform: translateX(-100%);}
    to{transform: translateX(0%);}
    
}

.heading-font{
    font-family: 'Signika Negative', sans-serif;
}

.hover-3 {
    --b: 0.1em;   /* the thickness of the line */
    --c: #1095c1; /* the color */
    
    color: #0000;
    padding-block: var(--b);
    background: 
      linear-gradient(var(--c) 50%,#000 0) 0% calc(100% - var(--_p,0%))/100% 200%,
      linear-gradient(var(--c) 0 0) 0% var(--_p,0%)/var(--_p,0%) var(--b) no-repeat;
    -webkit-background-clip: text,padding-box;
            background-clip: text,padding-box;
    transition: .3s var(--_s,0s) linear,background-size .3s calc(.3s - var(--_s,0s));
  }
  .hover-3:hover {
    --_p: 100%;
    --_s: .3s;
  }
  .hover-4 {
    border: 8px solid;
    border-image: repeating-linear-gradient(135deg,#F8CA00 0 10px,#E97F02 0 20px,#BD1550 0 30px) 8;
    -webkit-mask: 
      conic-gradient(from 180deg at top 8px right 8px, #0000 90deg,#000 0)
       var(--_i,200%) 0  /200% var(--_i,8px) border-box no-repeat,
      conic-gradient(at bottom 8px left  8px,  #0000 90deg,#000 0)
       0   var(--_i,200%)/var(--_i,8px) 200% border-box no-repeat,
      linear-gradient(#000 0 0) padding-box no-repeat;
    transition: .3s, -webkit-mask-position .3s .3s;
  }

  .intro-animation-left{
    animation: starting 1s ease-out;
  }
  @keyframes starting {
    from{transform: translateX(-50%);}
    to{transform: translateY(0%);} 
}
  .intro-animation-right{
    animation: starting-right 1s ease-out;
  }
  @keyframes starting-right {
    from{transform: translateX(50%);}
    to{transform: translateY(0%);} 
}
@media screen {
  
}
.box {
    background: white;
    margin: auto;
    /* margin-top: 5%; */
    /* max-width: 30%; */
    padding: 20px 50px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  }
  
  .box:hover {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    animation-duration: 0.25s;
    border-left: 8px solid #00E1FF;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
  ul.list{
    margin-left: 30px;
    font-weight: 600;
    list-style-type: disc;
  }
  .hover-property-div:hover .hover-div{
    transform: translateX(0%);
  }
  .hover-div{
    transform: translateX(-100%);
    transition: .5s;
  }
  .border-1{
    border: .25px solid lightgray;
  }
  .tabs {
    display: flex;
    justify-content: start;
    margin-bottom: 20px;
  }

  button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #00E1FE;
    border: none;
    margin-left: 5%;
    border-radius: 5px;
  }

  .content {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .course-content  {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #323B8D;
    color: white;

  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    margin: 10px 0;
    padding-left: 10px;
    position: relative;
  }

  li::before {
    content: '';
    position: absolute;
    left: 0;
  }
  @media screen {
    
  }
 .transform{
  transform: translateY(-10%);
  transition: .5s;
 }
.transform:hover{
  transform: translateY(0%);
 }
 
.button-43 {
  background-image: linear-gradient(-180deg, #37AEE2 0%, #1E96C8 100%);
  border-radius: .5rem;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-size: 16px;
  justify-content: center;
  padding: 1rem 1rem;
  text-decoration: none;
  width: 100%;
  border: 0;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-43:hover {
  background-image: linear-gradient(-180deg, #1D95C9 0%, #17759C 100%);
}

@media (min-width: 768px) {
  .button-43 {
    padding: 1rem 2rem;
  }
}
.button-44 {
  background-color: white;
  border-radius: .5rem;
  box-sizing: border-box;
  color: black;
  display: flex;
  font-size: 16px;
  justify-content: center;
  padding: 1rem 1rem;
  text-decoration: none;
  width: 100%;
  border: 0;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  border: 2px solid #37AEE2;
}

.button-44:hover {
  background-image: linear-gradient(-180deg, #1D95C9 0%, #17759C 100%);
}

@media (min-width: 768px) {
  .button-44 {
    padding: 1rem 2rem;
  }
}
